import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react'; 
import {
  AiFillHeart,
  AiOutlineHeart,
  AiFillPlusCircle,
  AiOutlinePlusCircle
} from "react-icons/ai";

// Iconos personalizados
const DessertIcon = ({ className }) => (
  <Icon icon="ep:dessert" className={className} />
);
DessertIcon.propTypes = { className: PropTypes.string };

const BowlSaladIcon = ({ className }) => (
  <Icon icon="fluent:bowl-salad-20-filled" className={className} />
);
BowlSaladIcon.propTypes = { className: PropTypes.string };

const ColdDrinkIcon = ({ className }) => (
  <Icon icon="ep:cold-drink" className={className} />
);
ColdDrinkIcon.propTypes = { className: PropTypes.string };

const CutOfMeatIcon = ({ className }) => (
  <Icon icon="fluent-emoji-high-contrast:cut-of-meat" className={className} />
);
CutOfMeatIcon.propTypes = { className: PropTypes.string };

const ShareIcon = ({ className }) => (
  <Icon icon="ic:baseline-ios-share" className={className} />
);
ShareIcon.propTypes = { className: PropTypes.string };

const ChampagnePartyAlcoholIcon = ({ className }) => (
  <Icon icon="streamline:champagne-party-alcohol" className={className} />
);
ChampagnePartyAlcoholIcon.propTypes = { className: PropTypes.string };

const RestaurantIcon = ({ className }) => (
  <Icon icon="material-symbols:restaurant" className={className} style={{ color: "white" }} />
);
RestaurantIcon.propTypes = { className: PropTypes.string };

const GourmetIcon = ({ className }) => (
  <Icon icon="emojione-monotone:shallow-pan-of-food" className={className} />
);
GourmetIcon.propTypes = { className: PropTypes.string };

const EntrantesIcon = ({ className }) => (
  <Icon icon="fluent:food-toast-16-filled" className={className} />
);
EntrantesIcon.propTypes = { className: PropTypes.string };

const FrescosIcon = ({ className }) => (
  <Icon icon="hugeicons:organic-food" className={className} />
);
FrescosIcon.propTypes = { className: PropTypes.string };

const MarTierraIcon = ({ className }) => (
  <Icon icon="fluent:food-fish-20-regular" className={className} />
);
MarTierraIcon.propTypes = { className: PropTypes.string };

const InfantilIcon = ({ className }) => (
  <Icon icon="mdi:food-outline" className={className} />
);
InfantilIcon.propTypes = { className: PropTypes.string };

const FlameIcon = ({ className }) => (
  <Icon icon="tabler:flame" className={className} />
);
FlameIcon.propTypes = { className: PropTypes.string };

const ALLERGENS_MAP = {};

const PostComponent = ({
  post,
  expandedPost,
  handleExpand,
  translations,
  selectedLanguage,
  toggleFavorite,
  favorites,
  addToOrders,
  orders,
  navbarHeight,
  activeSection
}) => {
  const infoVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: 20, opacity: 0 }
  };

  const postVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, ease: "easeOut" }
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: { duration: 0.3 }
    }
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      transition: { type: "spring", stiffness: 300, damping: 20 }
    },
    tap: { scale: 0.95 }
  };

  return (
    <motion.div
      key={`${activeSection}-${post.id}`}
      id={`post-${activeSection}-${post.id}`}
      variants={postVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="snap-start snap-always relative overflow-visible"
      style={{ height: `calc(100vh - ${navbarHeight}px)` }}
    >
      {/* Fondo: video */}
      <motion.div className="absolute inset-0">
        {post.video ? (
          <motion.video
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            src={post.video}
            className="w-full h-full object-cover pointer-events-none"
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
          />
        ) : (
          <div className="w-full h-full bg-gray-800" />
        )}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/40 to-black/80 pointer-events-none" />
        <AnimatePresence>
          {expandedPost === post.id && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="absolute inset-0 bg-black/50 pointer-events-none"
              style={{ zIndex: 10 }}
            />
          )}
        </AnimatePresence>
      </motion.div>

      {/* Botones de acción */}
      <div
        className="absolute flex flex-col gap-4 z-30"
        style={{
          bottom: `${navbarHeight + 40}px`,
          right: "16px"
        }}
      >
        {/* Favorito */}
        <motion.button
          whileHover={buttonVariants.hover}
          whileTap={buttonVariants.tap}
          className="w-16 h-16 backdrop-blur-sm bg-black/50 rounded-full hover:bg-amber-500 active:bg-amber-700 flex items-center justify-center"
          onClick={() => toggleFavorite(post)}
          aria-label={
            favorites.find((item) => item.id === post.id)
              ? "Eliminar favorito"
              : "Agregar a favoritos"
          }
        >
          <motion.div
            animate={{
              scale: favorites.find((item) => item.id === post.id)
                ? [1, 1.2, 1]
                : 1
            }}
            transition={{ duration: 0.2 }}
          >
            {favorites.find((item) => item.id === post.id) ? (
              <AiFillHeart className="text-white w-6 h-6" />
            ) : (
              <AiOutlineHeart className="text-white w-6 h-6" />
            )}
          </motion.div>
        </motion.button>

        {/* Añadir a pedidos */}
        <motion.button
          whileHover={buttonVariants.hover}
          whileTap={buttonVariants.tap}
          className="w-16 h-16 backdrop-blur-sm bg-black/50 rounded-full hover:bg-amber-500 active:bg-amber-700 flex items-center justify-center"
          onClick={() => addToOrders(post)}
          aria-label={
            orders.find((item) => item.id === post.id)
              ? "Eliminar de pedidos"
              : "Agregar a pedidos"
          }
        >
          <motion.div
            animate={{
              scale: orders.find((item) => item.id === post.id)
                ? [1, 1.2, 1]
                : 1
            }}
            transition={{ duration: 0.2 }}
          >
            {orders.find((item) => item.id === post.id) ? (
              <AiFillPlusCircle className="text-white w-6 h-6" />
            ) : (
              <AiOutlinePlusCircle className="text-white w-6 h-6" />
            )}
          </motion.div>
        </motion.button>

        {/*
          Botón de Compartir - ELIMINADO
          <motion.button ...>
            <ShareIcon ... />
          </motion.button>
        */}
      </div>

      {/* Información del post */}
      <div
        className="absolute left-0 right-0 z-20 px-4"
        style={{
          bottom: `${navbarHeight + 40}px`,
          padding: "0 1rem",
          paddingRight: "70px"
        }}
      >
        <div>
          <motion.h2
            className="text-xl font-bold mt-2 mb-1"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            {post.title}
          </motion.h2>
          <motion.span
            className="text-lg font-bold text-amber-500 mb-2 block"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            {post.price}
          </motion.span>

          <motion.p
            className="text-gray-200 text-sm mb-1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            @{post.author}
          </motion.p>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <p className="text-gray-200 text-base mb-2">
              {expandedPost === post.id
                ? post.longDescription
                : post.description}
            </p>

            <AnimatePresence>
              {expandedPost === post.id && post.extraInfo && (
                <motion.div
                  key="extra"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={ALLERGENS_MAP}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                  className="mb-4"
                >
                  <p className="text-gray-200 text-sm mb-2">
                    {post.extraInfo}
                  </p>
                </motion.div>
              )}
            </AnimatePresence>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleExpand(post.id)}
              className="text-amber-500 hover:text-amber-400 font-medium mt-2 mb-4"
            >
              {expandedPost === post.id
                ? translations[selectedLanguage].seeLess
                : translations[selectedLanguage].seeMore}
            </motion.button>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

PostComponent.propTypes = {
  post: PropTypes.object.isRequired,
  expandedPost: PropTypes.number,
  handleExpand: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  favorites: PropTypes.array.isRequired,
  addToOrders: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  navbarHeight: PropTypes.number.isRequired,
  activeSection: PropTypes.string.isRequired
};

const TikTokFoodUI = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [expandedPost, setExpandedPost] = useState(null);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("es");
  const [favorites, setFavorites] = useState([]);
  const [orders, setOrders] = useState([]);
  const [activeMode, setActiveMode] = useState("restaurante");
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);

  const navbarHeight = 60;

  const translations = {
    es: {
      title: "MENÚ",
      seeMore: "Ver Más",
      seeLess: "Ver Menos",
      selectLanguage: "Seleccionar idioma",
      close: "Cerrar",
      list: "List"
    },
    en: {
      title: "MENU",
      seeMore: "See More",
      seeLess: "See Less",
      selectLanguage: "Select Language",
      close: "Close",
      list: "List"
    }
  };

  // Datos completos (Restaurante)
  const DATA = {
    gourmet: [
      {
        id: 1,
        title: "Jamón Ibérico de Bellota",
        author: "chef",
        price: "29,00€",
        description: "Finas lonchas cortadas a mano, sabor y elegancia en cada bocado.",
        longDescription: "Finas lonchas cortadas a mano, sabor y elegancia en cada bocado.",
        video: "/video/Menu/SELECIÓN GOURMET/JAMÓN IBERICO DE BELLOTA.mp4"
      },
      {
        id: 2,
        title: "Cecina de la Casa",
        author: "chef",
        price: "16,00€",
        description: "Láminas de cecina curada artesanalmente, con aceite de oliva virgen extra.",
        longDescription: "Láminas de cecina curada artesanalmente, con aceite de oliva virgen extra.",
        video: "/video/Menu/SELECIÓN GOURMET/CECINA DE LA CASA.mp4"
      },
      {
        id: 3,
        title: "Tabla de Quesos Artesanos",
        author: "chef",
        price: "21,00€",
        description: "Una exclusiva selección de quesos con carácter y tradición.",
        longDescription: "Una exclusiva selección de quesos con carácter y tradición.",
        video: "/video/Menu/SELECIÓN GOURMET/TABLA DE QUESOS ARTESANOS.mp4"
      },
      {
        id: 4,
        title: "Anchoas de Santoña (Doña tomasa) con Tostada, queso azul ahumado",
        author: "chef",
        price: "21,00€",
        description: "Delicadas anchoas, servidas con pan tostado crujiente con queso azul ahumado.",
        longDescription: "Delicadas anchoas, servidas con pan tostado crujiente con queso azul ahumado.",
        video: "/video/Menu/SELECIÓN GOURMET/ANCHOAS.mp4"
      },
      {
        id: 5,
        title: "Salpicón de Mariscos con crema de ajo asado",
        author: "chef",
        price: "22,00€",
        description: "Un vibrante encuentro de mariscos frescos, en una sedosa crema de ajo.",
        longDescription: "Un vibrante encuentro de mariscos frescos, en una sedosa crema de ajo.",
        video: "/video/Menu/SELECIÓN GOURMET/ZAMBURIÑA A LA BRASA.mp4"
      }
    ],
    entrantes: [
      {
        id: 1,
        title: "Croquetas de Cecina",
        author: "chef",
        price: "18,00€",
        description: "Crujientes por fuera, cremosas por dentro, con papada ibérica.",
        longDescription: "Crujientes por fuera, cremosas por dentro, con papada ibérica.",
        video: "/video/Menu/ENTRANTES/CROQUETAS DE CECINA.mp4"
      },
      {
        id: 2,
        title: "Ensaladilla de Ventresca al Carbón",
        author: "chef",
        price: "14,00€",
        description: "Bonito al carbón con guindillas en vinagre, para un toque vibrante.",
        longDescription: "Bonito al carbón con guindillas en vinagre, para un toque vibrante.",
        video: "/video/Menu/ENTRANTES/ENSALADILLA DE VENTRESCA AL CARBÓN.mp4"
      },
      {
        id: 3,
        title: "Torrezno de Soria en Dos Cocciones",
        author: "chef",
        price: "14,00€",
        description: "Crujiente y jugoso, acompañado de batata asada y mojo versión la reserva.",
        longDescription: "Crujiente y jugoso, acompañado de batata asada y mojo versión la reserva.",
        video: "/video/Menu/ENTRANTES/TORREZNO DE SORIA.mp4"
      },
      {
        id: 4,
        title: "Patatas Bravas",
        author: "chef",
        price: "10,00€",
        description: "Nuestra salsa tradicional en una explosión de sabor.",
        longDescription: "Nuestra salsa tradicional en una explosión de sabor.",
        video: "/video/Menu/ENTRANTES/PATATAS BRAVAS.mp4"
      },
      {
        id: 5,
        title: "Alcachofas Confitadas",
        author: "chef",
        price: "18,00€",
        description: "Con carrillera de ternera y yema trufada. Un deleite gourmet.",
        longDescription: "Con carrillera de ternera y yema trufada. Un deleite gourmet.",
        video: "/video/Menu/ENTRANTES/ALCACHOFAS CONFITADAS.mp4"
      },
      {
        id: 6,
        title: "Merluza a la Romana",
        author: "chef",
        price: "16,00€",
        description: "Merluza dorada con alioli de cebollino.",
        longDescription: "Merluza dorada con alioli de cebollino.",
        video: "/video/Menu/ENTRANTES/Merluza a la Romana.mp4"
      },
      {
        id: 7,
        title: "Tortilla Cremosa - Chistorra Campeona 2022-2023",
        author: "chef",
        price: "14,00€",
        description: "Un suculento homenaje a los sabores tradicionales, con un toque innovador.",
        longDescription: "Un suculento homenaje a los sabores tradicionales, con un toque innovador.",
        video: "/video/Menu/ENTRANTES/TORTILLA CREMOSA.mp4"
      },
      {
        id: 8,
        title: "Berenjena al Josper",
        author: "chef",
        price: "10,00€",
        description: "Asada al Josper con salsa napolitana y parmesano fundido.",
        longDescription: "Asada al Josper con salsa napolitana y parmesano fundido.",
        video: "/video/Menu/ENTRANTES/BERENJENA AL JOSPER.mp4"
      },
      {
        id: 9,
        title: "Pulpo a la Brasa con Salsa Barbacoa Japonesa",
        author: "chef",
        price: "24,00€",
        description: "Pulpo braseado, servido con batata asada y alioli de sobrasada.",
        longDescription: "Pulpo braseado, servido con batata asada y alioli de sobrasada.",
        video: "/video/Menu/ENTRANTES/PULPO A LA BRASA.mp4"
      }
    ],
    frescos: [
      {
        id: 1,
        title: "Tomate Ecológico Aliñado",
        author: "chef",
        price: "12,00€",
        description: "Tomate de temporada con cebolla fresca y aceite de oliva virgen extra.",
        longDescription: "Tomate de temporada con cebolla fresca y aceite de oliva virgen extra. Frescura y sabor auténtico.",
        video: "/video/Menu/FRESCOR DE LA HUERTA/TOMATE ECOLÓGICO ALIÑADO.mp4"
      },
      {
        id: 2,
        title: "Ensalada Fresca la reserva",
        author: "chef",
        price: "13,00€",
        description: "Mezclume fresca, Queso azul, manzana, nuez caramelizada, aceite de oliva ahumado y tomates Cherry.",
        longDescription: "Mezclume fresca, Queso azul, manzana, nuez caramelizada, aceite de oliva ahumado y tomates Cherry. Un placer elegante y sabroso.",
        video: "/video/Menu/FRESCOR DE LA HUERTA/ENSALADA FRESCA LA RESERVA.mp4"
      },
      {
        id: 3,
        title: "César de Salmón Ahumado",
        author: "chef",
        price: "14,00€",
        description: "Lechuga romana con salmón ahumado y nuestra salsa casar de calamatas.",
        longDescription: "Lechuga romana con salmón ahumado y nuestra salsa casar de calamatas.",
        video: "/video/Menu/FRESCOR DE LA HUERTA/CÉSAR DE SALMÓN.mp4"
      },
      {
        id: 4,
        title: "Ensalada mixta",
        author: "chef",
        price: "11,00€",
        description: "Ensalada Mixta: Fresca y ligera, con tomate fresco, mix de lechugas, cebolla morada.",
        longDescription: "Una ensalada simple y deliciosa.",
        video: "/video/Menu/FRESCOR DE LA HUERTA/ENSALADA MIXTA.mp4"
      }
    ],
    marytierra: [
      {
        id:1,
        title: "Paella de Gamba y Mariscos",
        author: "chef",
        price: "21,00€",
        description: "Una deliciosa combinación de gambas y mariscos frescos, cocinados en una paella tradicional.",
        longDescription: "Precio por persona.",
        video: "/video/Menu/DEL MAR Y DE LA TIERRA/PAELLA DEL MAR.mp4"
      },
      {
        id:2,
        title: "Paella de Ternera, Papada y Alcachofas",
        author: "chef",
        price: "21,00€",
        description: "Un sabroso arroz con ternera, papada y alcachofas confitadas.",
        longDescription: "Una combinación rica y memorable. Precio por persona.",
        video: "/video/Menu/DEL MAR Y DE LA TIERRA/PAELLA VALENCIANA.mp4"
      },
      {
        id:3,
        title: "Chuletón de Vaca Gallega 40 dias de maduración (un kilo)",
        author: "chef",
        price: "80,00€",
        description: "Jugoso y tierno, a la parrilla para resaltar su sabor natural.",
        longDescription: "Una celebración de la buena carne, con patatas y pimientos del piquillo caramelizados.",
        video: "/video/Menu/DEL MAR Y DE LA TIERRA/CHULETONES DE VACA.mp4"
      },
      {
        id:4,
        title: "Carrillera de Ternera en su Jugo",
        author: "chef",
        price: "23,00€",
        description: "Tierna y melosa, cocinada a fuego lento para concentrar su jugo.",
        longDescription: "Cada bocado con un sabor profundo e inigualable.",
        video: "/video/Menu/DEL MAR Y DE LA TIERRA/CARRILLERA DE TERNERA EN SU JUGO.mp4"
      },
      {
        id:5,
        title: "Solomillo de vaca– con batata asada",
        author: "chef",
        price: "26,00€",
        description: "Corte jugoso y tierno, cocinado en el Josper para un sabor intenso y ahumado.",
        longDescription: "Un sabor inigualable.",
        video: "/video/Menu/DEL MAR Y DE LA TIERRA/SOLOMILLO DE VACA.mp4"
      },
      {
        id:8,
        title: "Lomo Bajo Trinchado",
        author: "chef",
        price: "22,00€",
        description: "Jugoso lomo bajo con patatas y pimientos del Padrón.",
        longDescription: "Un sabor auténtico.",
        video: "/video/Menu/DEL MAR Y DE LA TIERRA/LOMO BAJO TRINCHADO.mp4"
      }
    ],
    infantil: [
      {
        id:1,
        title: "Pasta Napolitana para Niños (Menú Infantil)",
        author: "chef",
        price: "14,00€",
        description: "Deliciosa pasta con salsa de tomate fresca al estilo napolitano.",
        longDescription: "Un plato simple y sabroso que encantará a los niños.",
        video: "/video/Menu/MENU INFANTIL/PASTA NAPOLITANA.mp4"
      },
      {
        id:2,
        title: "Burger con Patatas (Menú Infantil)",
        author: "chef",
        price: "Incluido en Menú (14€)",
        description: "Hamburguesa preparada a la perfección, acompañada de crujientes patatas fritas.",
        longDescription: "Ideal para nuestros pequeños exploradores culinarios. Postre: Helado.",
        video: "/video/Menu/MENU INFANTIL/BURGUER CON PATATAS.mp4"
      }
    ],
    dulces: [
      {
        id:2,
        title: "Tarta de Queso Manchego",
        author: "chef",
        price: "7,00€",
        description: "Al horno, suave y sabrosa.",
        longDescription: "Un postre tradicional.",
        video: "/video/Menu/POSTRES/TARTA DE QUESO MANCHEGO.mp4"
      },
      {
        id:3,
        title: "Brownie de Chocolate Belga",
        author: "chef",
        price: "7,50€",
        description: "Rico y decadente, con nueces caramelizadas.",
        longDescription: "Un placer dulce y crujiente.",
        video: "/video/Menu/POSTRES/BROWNIE DE CHOCOLATE BELGA.mp4"
      },
      {
        id:4,
        title: "Helados",
        author: "chef",
        price: "6,50€",
        description: "Vainilla canela, chocolate, caramelo",
        longDescription: "Cremosos y refrescantes.",
        video: "/video/Menu/POSTRES/HELADO.mp4"
      },
      {
        id:5,
        title: "Sorbete",
        author: "chef",
        price: "6,50€",
        description: "Limón.",
        longDescription: "Refrescante y ligero.",
        video: "/video/Menu/POSTRES/SORBETE.mp4"
      }
    ],
    bebidas: [
      {
        id:1,
        title: "Javier Sanz Verdejo 2023",
        author: "bartender",
        price: "Copa 5,50€ / Botella 26,00€",
        description: "D.O. Rueda",
        longDescription: "Vino blanco fresco y aromático.",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:2,
        title: "Terras do Cigarrón Blanco 2023",
        author: "bartender",
        price: "Copa 4,00€ / Botella 22,00€",
        description: "D.O. Monterrei",
        longDescription: "Vino blanco suave y afrutado.",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:3,
        title: "Artazuri Rosado 2023",
        author: "bartender",
        price: "Copa 4,00€ / Botella 21,00€",
        description: "D.O. Navarra",
        longDescription: "Rosado fresco y ligero.",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:4,
        title: "Dr Loosen dry blanco 2023",
        author: "bartender",
        price: "Copa 4,50€ / Botella 24,00€",
        description: "D.O. Mosel",
        longDescription: "Vino blanco seco y elegante.",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:5,
        title: "Antidoto 2023",
        author: "bartender",
        price: "Copa 6,50€ / Botella 36,00€",
        description: "D.O. Ribera del Duero",
        longDescription: "Vino tinto con cuerpo y aroma complejo.",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:6,
        title: "El Jardín de La Emperatriz Tinto 2020",
        author: "bartender",
        price: "Copa 4,00€ / Botella 22,00€",
        description: "D.O. Rioja",
        longDescription: "Tinto equilibrado y sabroso.",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:7,
        title: "Marqués de Murrieta Reserva 2019",
        author: "bartender",
        price: "Copa 7,50€ / Botella 44,00€",
        description: "D.O. Rioja",
        longDescription: "Un clásico Rioja reserva de gran elegancia.",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:8,
        title: "Heineken tercio",
        author: "bartender",
        price: "5,00€",
        description: "",
        longDescription: "",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:9,
        title: "Heineken copa",
        author: "bartender",
        price: "4,50€",
        description: "",
        longDescription: "",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:10,
        title: "Heineken 0.0",
        author: "bartender",
        price: "4,00€",
        description: "",
        longDescription: "",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:11,
        title: "Refrescos",
        author: "bartender",
        price: "4,00€",
        description: "",
        longDescription: "",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:12,
        title: "Agua",
        author: "bartender",
        price: "5,00€",
        description: "",
        longDescription: "",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:13,
        title: "Agua con Gas",
        author: "bartender",
        price: "3,50€",
        description: "",
        longDescription: "",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:14,
        title: "Zumos",
        author: "bartender",
        price: "4,00€",
        description: "",
        longDescription: "",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:15,
        title: "Café",
        author: "bartender",
        price: "2,50€",
        description: "",
        longDescription: "",
        video: "/video/Menu/SELECIÓN GOURMET/"
      },
      {
        id:16,
        title: "Copas",
        author: "bartender",
        price: "10,00€",
        description: "",
        longDescription: "",
        video: "/video/Menu/SELECIÓN GOURMET/"
      }
    ]
  };

  // Datos para el modo discoteca con shows
  const DATA_DISCOTECA = {
    shows: [
      {
        id: 1,
        title: "Show de Botellas",
        author: "DJ",
        price: "500€",
        description: "Un espectáculo de luces y música con presentación de botellas.",
        longDescription: "Este show incluye la presentación de varias botellas con un show de luces y sonido, ideal para comenzar la noche.",
        video: "/video/Menu/VIP DISCOTECA/SHOW BOTELLA (PREMIUM).mp4"
      },
      {
        id: 2,
        title: "Show de Botellas Premium",
        author: "DJ",
        price: "800€",
        description: "La experiencia premium con botellas premium y bailarines.",
        longDescription: "Un show premium que combina botellas de alta gama con una puesta en escena más elaborada.",
        video: "/video/Menu//VIP DISCOTECA/SHOW BOTELLA MOET.mp4"
      },
      {
        id: 3,
        title: "Show de Botellas Moët",
        author: "DJ",
        price: "1200€",
        description: "Botellas Moët y un show elegante y sofisticado.",
        longDescription: "La elegancia del champagne Moët combinada con efectos visuales de lujo.",
        video: "/video/Menu/VIP DISCOTECA/SHOW BOTELLA (NO PREMIUN).mp4"
      },
      {
        id: 5,
        title: "Show de Botellas Go Go Go",
        author: "DJ",
        price: "1000€",
        description: "¡Energía sin límites y bailarines go-go!",
        longDescription: "Este show incluye bailarines go-go y botellas presentadas de forma dinámica.",
        video: "/video/Menu/VIP DISCOTECA/SHOW GOGO.mp4"
      },
      {
        id: 6,
        title: "Show Moet",
        author: "DJ",
        price: "1500€",
        description: "La máxima expresión de lujo con Moët & Chandon.",
        longDescription: "Este show está dedicado completamente a Moët & Chandon, con una experiencia inigualable.",
        video: "/video/Menu/VIP DISCOTECA/SHOW MOET.mp4"
      },
    ]
  };

  const SECTIONS = [
    { id: "gourmet", label: "Gourmet", icon: GourmetIcon, posts: DATA.gourmet },
    { id: "entrantes", label: "Entrantes", icon: EntrantesIcon, posts: DATA.entrantes },
    { id: "frescos", label: "Frescos", icon: FrescosIcon, posts: DATA.frescos },
    { id: "marytierra", label: "Mar y Tierra", icon: MarTierraIcon, posts: DATA.marytierra },
    { id: "infantil", label: "Menú Infantil", icon: InfantilIcon, posts: DATA.infantil },
    { id: "dulces", label: "Dulces", icon: DessertIcon, posts: DATA.dulces },
    { id: "bebidas", label: "Bebidas", icon: ColdDrinkIcon, posts: DATA.bebidas },
  ];

  const SECTIONS_DISCOTECA = [
    { id: "shows", label: "Shows", icon: FlameIcon, posts: DATA_DISCOTECA.shows }
  ];

  const currentSections = activeMode === "restaurante" ? SECTIONS : SECTIONS_DISCOTECA;
  const activeSection = currentSections[activeSectionIndex];
  const posts = activeSection.posts || [];

  const handleExpand = (postId) => {
    setExpandedPost(expandedPost === postId ? null : postId);
  };

  const toggleFavorite = (post) => {
    setFavorites((prev) => {
      const exists = prev.find((item) => item.id === post.id);
      if (exists) {
        return prev.filter((item) => item.id !== post.id);
      }
      return [...prev, post];
    });
  };

  const addToOrders = (post) => {
    setOrders((prev) => {
      const exists = prev.find((item) => item.id === post.id);
      if (!exists) {
        return [...prev, post];
      }
      return prev;
    });
  };

  const [lastScrollY, setLastScrollY] = useState(window.scrollY);

  // Control de secciones al llegar arriba/abajo
  useEffect(() => {
    const handleScroll = () => {
      const bottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 50;
      const top = window.scrollY <= 0;

      if (bottom && activeSectionIndex < currentSections.length - 1) {
        setActiveSectionIndex(activeSectionIndex + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }

      if (top && activeSectionIndex > 0 && window.scrollY === 0 && lastScrollY < 5) {
        setActiveSectionIndex(activeSectionIndex - 1);
        setTimeout(() => {
          window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
        }, 300);
      }

      setLastScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeSectionIndex, currentSections.length, lastScrollY]);

  // * NUEVA LÓGICA: Pausar todos los videos al hacer scroll y reproducir solo el visible cuando se deja de hacer scroll
  useEffect(() => {
    let scrollTimeout;

    // Pausa todos los videos de la página
    const pauseAllVideos = () => {
      const videos = document.querySelectorAll("video");
      videos.forEach((video) => {
        video.pause();
      });
    };

    // Reproduce solo los videos completamente visibles en el viewport
    const handleVideoVisibility = () => {
      const videos = document.querySelectorAll("video");
      videos.forEach((video) => {
        const rect = video.getBoundingClientRect();
        // Chequeamos si está completamente en el viewport
        if (
          rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        ) {
          video.play();
        } else {
          video.pause();
        }
      });
    };

    const handleFullScroll = () => {
      // 1) Mientras se hace scroll, pausar todo
      pauseAllVideos();

      // 2) Cancelar un timeout anterior si existe
      if (scrollTimeout) clearTimeout(scrollTimeout);

      // 3) Esperar 200ms tras terminar de hacer scroll para reproducir el video visible
      scrollTimeout = setTimeout(() => {
        handleVideoVisibility();
      }, 200);
    };

    window.addEventListener("scroll", handleFullScroll);

    // Revisar videos al montar el componente (por si inicia con scroll)
    handleVideoVisibility();

    return () => {
      window.removeEventListener("scroll", handleFullScroll);
    };
  }, []);

  const handleLanguageChange = (langCode) => {
    setSelectedLanguage(langCode);
    setShowLanguageModal(false);
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      transition: { type: "spring", stiffness: 300, damping: 20 }
    },
    tap: { scale: 0.95 }
  };

  const modalVariants = {
    hidden: { opacity: 0, y: 50, scale: 0.95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { type: "spring", stiffness: 300, damping: 30 }
    }
  };

  // Lógica para mostrar solo 5 íconos a la vez en el nav
  const iconsPerView = 5;
  const [iconStartIndex, setIconStartIndex] = useState(0);

  const totalIcons = currentSections.length;
  const visibleSections = currentSections.slice(iconStartIndex, iconStartIndex + iconsPerView);

  const canScrollLeft = iconStartIndex > 0;
  const canScrollRight = iconStartIndex + iconsPerView < totalIcons;

  const scrollRight = () => {
    if (canScrollRight) {
      setIconStartIndex(iconStartIndex + 1);
    }
  };

  const scrollLeft = () => {
    if (canScrollLeft) {
      setIconStartIndex(iconStartIndex - 1);
    }
  };

  return (
    <div className="h-screen overflow-y-scroll snap-y snap-mandatory bg-black text-white relative">
      {showIntro ? (
        <div className="h-full w-full flex items-center justify-center">
          <video
            src="/video/portada.mp4"
            className="w-full h-full object-cover pointer-events-none"
            autoPlay
            muted
            playsInline
            preload="auto"
            onEnded={() => setShowIntro(false)}
          />
        </div>
      ) : (
        <>
          {/* Header */}
          <motion.header
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="fixed top-0 left-0 right-0 z-50 backdrop-blur-md bg-gradient-to-b from-black/80 via-black/60 to-transparent py-4"
          >
            <h1 className="text-2xl font-bold text-center text-white tracking-wider">
              {activeSection.label.toUpperCase()}
            </h1>
          </motion.header>

          {/* Botones superiores (Modo, Idioma) */}
          <div className="fixed top-4 right-4 z-50 flex flex-col gap-4">
            {/* Cambiar Modo */}
            <motion.button
              whileHover={buttonVariants.hover}
              whileTap={buttonVariants.tap}
              className="w-16 h-16 backdrop-blur-sm bg-black/50 rounded-full hover:bg-amber-500 active:bg-amber-700 flex items-center justify-center"
              onClick={() => {
                const newMode = activeMode === "restaurante" ? "discoteca" : "restaurante";
                setActiveMode(newMode);
                setActiveSectionIndex(0);
                setIconStartIndex(0);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              aria-label={activeMode === "restaurante" ? "Cambiar a Discoteca" : "Cambiar a Restaurante"}
            >
              {activeMode === "restaurante" ? (
                <ChampagnePartyAlcoholIcon className="w-6 h-6 text-white" />
              ) : (
                <RestaurantIcon className="w-6 h-6 text-white" />
              )}
            </motion.button>

            {/* Idioma */}
            <motion.button
              whileHover={buttonVariants.hover}
              whileTap={buttonVariants.tap}
              className="w-16 h-16 backdrop-blur-sm bg-black/50 rounded-full hover:bg-amber-500 active:bg-amber-700 flex items-center justify-center"
              onClick={() => setShowLanguageModal(true)}
              aria-label="Seleccionar Idioma"
            >
              {selectedLanguage === "es" ? (
                <img src="/img/spain.svg" alt="Spanish" className="w-8 h-8" />
              ) : (
                <img src="/img/usa.svg" alt="English" className="w-8 h-8" />
              )}
            </motion.button>
          </div>

          {/* Modal de idioma */}
          <AnimatePresence>
            {showLanguageModal && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={modalVariants}
                className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center backdrop-blur-sm"
              >
                <div className="bg-gray-900/90 backdrop-blur-md p-6 rounded-lg w-full max-w-md">
                  <h2 className="text-xl font-bold mb-4 text-center">
                    {translations[selectedLanguage].selectLanguage}
                  </h2>
                  <div className="grid gap-2">
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className={`w-full text-left px-4 py-3 rounded-lg flex items-center ${
                        selectedLanguage === "es" ? "bg-amber-500" : "bg-gray-800"
                      } hover:bg-amber-600 transition-colors`}
                      onClick={() => handleLanguageChange("es")}
                    >
                      <img src="/img/spain.svg" alt="Spanish" className="w-6 h-6 mr-3" />
                      <span className="text-lg">Spanish</span>
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className={`w-full text-left px-4 py-3 rounded-lg flex items-center ${
                        selectedLanguage === "en" ? "bg-amber-500" : "bg-gray-800"
                      } hover:bg-amber-600 transition-colors`}
                      onClick={() => handleLanguageChange("en")}
                    >
                      <img src="/img/usa.svg" alt="English" className="w-6 h-6 mr-3" />
                      <span className="text-lg">English</span>
                    </motion.button>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="mt-4 w-full py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
                    onClick={() => setShowLanguageModal(false)}
                  >
                    ✕ {translations[selectedLanguage].close}
                  </motion.button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Contenido Principal */}
          <div className="pt-16">
            <AnimatePresence>
              {posts.map((post) => (
                <PostComponent
                  key={`${activeSection.id}-${post.id}`}
                  post={post}
                  expandedPost={expandedPost}
                  handleExpand={handleExpand}
                  translations={translations}
                  selectedLanguage={selectedLanguage}
                  toggleFavorite={toggleFavorite}
                  favorites={favorites}
                  addToOrders={addToOrders}
                  orders={orders}
                  navbarHeight={navbarHeight}
                  activeSection={activeSection.id}
                />
              ))}
            </AnimatePresence>
          </div>

          {/* Navegación inferior con paginación de íconos */}
          <motion.nav
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="fixed bottom-0 left-0 right-0 backdrop-blur-md bg-gradient-to-t from-black/90 to-transparent px-4 py-2 z-50 overflow-hidden"
          >
            <div className="max-w-screen-xl mx-auto relative flex items-center justify-center">
              {canScrollLeft && (
                <motion.button
                  whileHover={buttonVariants.hover}
                  whileTap={buttonVariants.tap}
                  onClick={scrollLeft}
                  className="text-white text-2xl mr-2"
                  aria-label="Scroll Left"
                >
                  ←
                </motion.button>
              )}

              <div className="flex gap-4 justify-center">
                {visibleSections.map((section, index) => {
                  const IconComp = section.icon;
                  const realIndex = iconStartIndex + index;
                  return (
                    <motion.button
                      key={section.id}
                      whileHover={buttonVariants.hover}
                      whileTap={buttonVariants.tap}
                      className={`flex flex-col items-center p-2 focus:outline-none focus:ring-amber-500 rounded-lg ${
                        activeSectionIndex === realIndex
                          ? "text-amber-500"
                          : "text-gray-400 hover:text-white"
                      }`}
                      onClick={() => {
                        setActiveSectionIndex(realIndex);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      aria-label={section.label}
                    >
                      <IconComp className="text-3xl" />
                    </motion.button>
                  );
                })}
              </div>

              {canScrollRight && (
                <motion.button
                  whileHover={buttonVariants.hover}
                  whileTap={buttonVariants.tap}
                  onClick={scrollRight}
                  className="text-white text-2xl ml-2"
                  aria-label="Scroll Right"
                >
                  →
                </motion.button>
              )}
            </div>
          </motion.nav>
        </>
      )}
    </div>
  );
};

export default TikTokFoodUI;
